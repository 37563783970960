* {
  padding: 0;
  margin: 0;
  font-family: 'Almarai', sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  /* --bg-body: #F1FAFF; */
  --bg-body: #f5f8fa;
  --primary-color: #743C91;
  --primary-color-hover: #6a3684;
  --primary-color-opacity: rgba(116, 60, 145, 0.5);
  --primary-color-light: #743c911a;
  /* --primary-color: #126C66; */
  /* --primary-color-opacity: rgba(18, 108, 102, 0.502); */
  /* --green-light-color: #743C91; */
  /* --green-light-color: #B8D0B2; */
  /* --green-light-color-hover: #9bae97; */
  --gray: #858688;
  --gray-hover: #727375;
  --green-color: #29CC39;
  --green-opacity-color: rgba(40, 205, 57, 0.3);
  --red-color: #DC3545;
  --red-opacity-color: rgba(220, 53, 69, 0.3);
  --yellow-color: #ffc107;
  --yellow-opacity-color: rgba(255, 193, 7, 0.3);
  --black-color: #1E1E1E;
  --black-color-opacity: rgba(30, 30, 30, 0.2);
  --timeing: 0.3s;
  --radius: 0.625rem;
  --color-blue: #9DC0D0;
  --red: #ff4d4f;
  --yellow: #ffc107;
}

.Triangles {
  position: absolute;
  bottom: -35px;
  width: 100%;
  height: 35px;
  display: flex;
  background-image: linear-gradient(135deg, var(--primary-color) 25%, transparent 25%),
    linear-gradient(225deg, var(--primary-color) 25%, transparent 25%);
  background-size: 50px 50px;
}

.style-drop-drog {
  position: relative;
}

/* start home page */
.title-head-special {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 50px 0;
}


.title-head-special div {
  font-weight: bolder;
  font-size: 30px;
  display: inline;
  position: relative;
  z-index: 100;
}

.title-head-special div::before {
  content: "";
  position: absolute;
  background-color: var(--primary-color-opacity);
  width: 100%;
  height: 7px;
  z-index: -1;
  border-radius: 50px;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.active-header {
  transform: scale(0);
  /* top: -100px; */
  transition: var(--timeing);
}

.landing-page-img {
  width: 500px;
  height: 350px;
}

.landing-page-img-wavy {
  width: 700px;
  height: 350px;
}

.style-img-goal {
  width: 300px;
  height: 300px;
  transition: var(--timeing);
}

.wavy-width-landing {
  width: 100px;
  transition: var(--timeing);
}

.hhhh {
  font-size: 72px;
  color: var(--primary-color);
  /* background: -webkit-linear-gradient(var(--primary-color), #ceb4db, var(--primary-color-hover));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  transition: var(--timeing);


}

.style-margin-about-text {
  margin-top: 80px;
  transition: var(--timeing);
}

.text-sub-title {
  font-size: 20px;
  transition: var(--timeing);
}

.text-title-icons-blobs {
  font-size: 16px;
  transition: var(--timeing);
}

.style-icons-homepage {
  width: 60px;
  height: 60px;
  transition: var(--timeing);
}

.style-blob-homepage {
  width: 110px;
  height: 110px;
  transition: var(--timeing);
}

.style-goal-homepage-opacisty {
  width: 70px;
  height: 70px;
  transition: var(--timeing);
}

.border-radius-contact-us-1 {
  border-radius: 0 var(--radius) var(--radius) 0;
}

.border-radius-contact-us-2 {
  border-radius: var(--radius) 0 0 var(--radius);
}

.style-translateY-advantages:nth-child(even) {
  transform: translateY(100px);
}

.style-footer-media {
  display: flex;
}


.style-footer-media-link {
  color: var(--bg-body);
  font-size: 28px;
  background-color: var(--primary-color-hover);
  transition: 0.2s;
  padding: 12px;
  display: flex;
  border-radius: 50px;
  margin: 5px;
}

.style-footer-media-link-1:hover {
  color: #fff;
  background-color: #1D9BF0;
  transition: 0.2s;
}

.style-footer-media-link-2:hover {
  color: #fff;
  background-color: #4267B2;
  transition: 0.2s;
}

.style-footer-media-link-3:hover {
  color: #fff;
  background-color: #EA4335;
  transition: 0.2s;
}

.style-footer-media-link-4:hover {
  color: #fff;
  background-color: #161B22;
  transition: 0.2s;
}

/* wavy */

.style-wavy-blur {
  /* background-color: var(--primary-color); */

  /* backdrop-filter: blur(40px); */
  /* background-color: red; */
  width: 230px;
  height: 100px;
  border-radius: 24px;
}

/* end home page */

/* start drawer */
:where(.css-dev-only-do-not-override-i0102m).ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0;
  overflow: auto;
}

/* end drawer */


.style-drop-drog input[type="file"]#fileUpload {
  border: none;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: transparent;
  transition: 1s;
  width: 100%;
  height: 300px;
  cursor: pointer;
  color: transparent;
}


.style-drop-drog input[type="file"]::-webkit-file-upload-button {
  background-color: transparent;
  border: none;
  color: transparent;
}

.style-drop-drog label {
  color: var(--black-color);
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;

}



.App {
  background-color: var(--bg-body);
  /* background-color: #000; */
}

body {
  /* min-height: 150vh; */
  /* background-color: #1E1E1E; */
  overflow-x: hidden !important;
  direction: rtl;
  background-color: var(--bg-body) !important;

  /* overflow: auto !important; */
}

/* scroll with border radius */
.style-scroll-radius {
  overflow: auto;
}

/* /////////////////////////////////////////sidebar start  */

/* start sidebar container */
.css-dip3t8 {
  background-color: #fff !important;
  box-shadow: 0 0 10px rgba(31, 31, 31, 0.05)
}

.css-1eyd4tp>.ps-menu-button {

  padding-left: 0px !important;
  padding-right: 0px !important;
}


/*  */
.css-1ytr39x>.ps-menu-button:hover {

  background-color: var(--primary-color-light) !important;
}

.css-1cbmtsf>.ps-menu-button:hover {

  background-color: var(--primary-color-light) !important;
}

/*  */


.css-8dyhm6>.ps-menu-button:hover {

  background-color: var(--primary-color-light) !important;
}

.css-8dyhm6>.ps-menu-button:hover {

  background-color: var(--primary-color-light) !important;
}

.css-1mclm0e>.ps-menu-button:hover {

  background-color: var(--primary-color-light) !important;
}

.css-8dyhm6>.ps-menu-button {

  padding-left: 25px !important;
  padding-right: 0px !important;
}

.css-1ytr39x>.ps-menu-button {

  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-1cbmtsf>.ps-menu-button {

  padding-left: 0px !important;
  padding-right: 0px !important;
}



.css-1ytr39x.ps-active,
.css-1cbmtsf.ps-active {
  background-color: var(--primary-color) !important;
  color: #fff;
}

.pro-menu-item a.active {
  color: var(--green);
  font-weight: 500;
}

.css-dip3t8,
.css-1jlhf24 {
  width: 230px;
}

.ps-submenu-root .css-1ytr39x .ps-menu-button,
.ps-submenu-root .css-1cbmtsf .ps-menu-button {
  padding-left: 0px !important;
  padding-right: 20px !important;
}


.css-1t2hy0d,
.css-1eki1b2 {
  transform: translate(-50%, -50%);
  top: 0 !important;
  width: 7px !important;
  height: 7px !important;
  margin-bottom: 3px !important;
}

:where(.css-dev-only-do-not-override-i0102m) a {
  /* color: #1677ff; */
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0s;
}

.css-dip3t8::-webkit-scrollbar {
  background-color: #ddd;
  height: 7px;
  border-radius: 30px;
  width: 7px;
}

.css-dip3t8::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 30px;
}

/* /////////////////////////////////////////sidebar end  */



/* for sidebar container only( Y) */
.style-sidebar-scroll-radius {
  overflow: auto;
  overflow-x: hidden;
}

.style-scroll-radius::-webkit-scrollbar,
.style-sidebar-scroll-radius::-webkit-scrollbar {
  background-color: var(--black-color-opacity);
  height: 7px;
  border-radius: 30px;
  width: 7px;
}

.style-scroll-radius::-webkit-scrollbar-thumb,
.style-sidebar-scroll-radius::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 30px;
}

/* scroll without border radius */
.style-scroll::-webkit-scrollbar {
  background-color: var(--black-color-opacity);
  height: 13px;
  width: 13px;
}

.style-scroll::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.style-tr-without-border-bottom:last-child {
  /* background-color: red; */
  border: 0 solid transparent;
}

/* placeholer loaing */
.placeholder-glow .placeholder {
  background-color: var(--primary-color);
  border-radius: 4px;
}


:where(.css-dev-only-do-not-override-i0102m).ant-carousel .slick-dots li {
  height: 6px !important;
  background: var(--primary-color-opacity);
  border-radius: 5px;
}

:where(.css-i0102m).ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: var(--primary-color-opacity) !important;
  border: 0;
  border-radius: 11px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s;
}

:where(.css-i0102m).ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: var(--primary-color) !important;
}

:where(.css-dev-only-do-not-override-i0102m).ant-carousel .slick-dots-bottom {
  bottom: -5px;
}

:where(.css-dev-only-do-not-override-i0102m).ant-carousel .slick-dots li button {
  height: 6px !important;
}

:where(.css-dev-only-do-not-override-i0102m).ant-carousel .slick-dots li.slick-active button {
  background: var(--primary-color);
  opacity: 1;
  border-radius: 5px;
  height: 6px !important;


}


/*************** Start SideBar ***************/
/* .sideBar-position {
  top: 0;
  right: 0;
  width: 266px;
  height: 100%;
  z-index: 1;

}

.sidebar-hover {
  transition: var(--timeing);
}

.sidebar-hover:hover {
  transition: var(--timeing);
  background-color: var(--primary-color);
}

.sidebar-hover:hover div {
  color: #fff;
}

.ps-menu-button {
  border-radius: 6px;
}


.sidebar-icon {
  background-color: var(--primary-color-opacity);
  border-radius: 11px;

}

.sidebar-hover:hover .sidebar-icon {
  background-color: var(--bg-body);
}


.ps-submenu-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}



.ps-menu-button:hover {
  background-color: var(--primary-color) !important;
  transition: var(--timeing);
}



.ps-menu-button:hover .css-1t2hy0d,
.ps-menu-button:hover .ps-open .css-1eki1b2 {
  color: #fff;
}


.ps-menu-button:hover .css-1097eso {
  background-color: #fff;

}





.ps-menu-button:hover .css-12w9als22 {
  color: #fff !important;
}

.ps-menu-button:hover .css-6a03ny22 {
  background-color: var(--bg-body);
} */


/*************** End SideBar ***************/
/* start img gallry */

@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-image {
  /* padding: 0 50px !important; */
  border-radius: 10px !important;
  width: 800px !important;
  height: 380px !important;
  /* height: 350px !important; */



}

.image-gallery-icon {
  color: #fff;
  transition: all .3s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  /* position: absolute; */
  z-index: 4;
  filter: drop-shadow(0 0 0) !important;
  box-shadow: 0 0 10px rgba(31, 31, 31, 0.1) !important
}

.image-gallery-svg {
  color: var(--primary-color);
  font-weight: bold;
}

#dark .image-gallery-svg {
  color: var(--green);
}

.image-gallery-right-nav {
  right: -25px;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;

  border-radius: 50px;
  padding: 0 !important;

}

.image-gallery-left-nav {
  left: -25px;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  box-shadow: transparent !important;
  border-radius: 50px;
  padding: 0 !important;

}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 43px;
  width: 60px;


}


.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;


  transform: translateY(-50%);
}

.image-gallery-bullet {
  background-color: var(--primary-color-light) !important;
  transform: translateY(45px) !important;
  border-radius: 50px !important;
  width: 10px !important;
  filter: drop-shadow(0 0 0) !important;
  box-shadow: none !important;
  border-width: 0 !important;
  margin: 0px 3px !important;
}

#dark .image-gallery-bullet {
  background-color: rgba(145, 204, 195, 0.3) !important;

}

.image-gallery-bullet.active {
  background-color: var(--primary-color) !important;
  transform: translateY(45px) !important;
  border-radius: 50px !important;
  width: 40px !important;
  filter: drop-shadow(0 0 0) !important;
  box-shadow: 0 0 10px rgba(31, 31, 31, 0.1) !important
}

#dark .image-gallery-bullet.active {
  background-color: var(--green) !important;

}

/* end img gallry */

:where(.css-dev-only-do-not-override-i0102m).ant-slider .ant-slider-track {
  position: absolute;
  background-color: var(--primary-color) !important;

}

:where(.css-dev-only-do-not-override-i0102m).ant-slider .ant-slider-handle:hover::after,
:where(.css-dev-only-do-not-override-i0102m).ant-slider .ant-slider-handle:active::after,
:where(.css-dev-only-do-not-override-i0102m).ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px var(--primary-color);

}

:where(.css-dev-only-do-not-override-i0102m).ant-slider .ant-slider-handle::after {

  box-shadow: 0 0 0 2px var(--primary-color-opacity);
}

/* Start Header Home Page */
#style-btn-start-service {
  border: 3px solid var(--primary-color);
  background-color: #fff;
  transition: var(--timeing);
}

#style-btn-start-service:hover {
  border: 3px solid var(--primary-color);
  background-color: var(--primary-color);
  /* color: var(--black-color); */
  color: #fff;
  transition: var(--timeing);
}

#style-btn-landing {
  border: 3px solid #fff;
  background-color: #fff;
  transition: var(--timeing);

}

#style-btn-landing:hover {
  border: 3px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}


#hover-btn-green-light {
  background-color: var(--gray);
}

#hover-btn-green-light:hover {
  background-color: var(--gray-hover)
}

#primary-hover {
  background-color: var(--primary-color);
}

#primary-hover:hover {
  background-color: var(--primary-color-hover);
}

.bars {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  transition: var(--timeing);

}

.bars div:nth-child(1) {
  width: 40px;
  height: 4px;
  border-radius: 50px;
  transform: translateY(8px);
  transition: var(--timeing);

}

.bars div:nth-child(2) {
  width: 30px;
  height: 4px;
  transition: var(--timeing);

  border-radius: 50px;
  transform: translateY(15px);

}

.bars div:nth-child(3) {
  width: 20px;
  height: 4px;
  border-radius: 50px;
  transform: translateY(22px);
  transition: var(--timeing);
}

.bars.active div:nth-child(1) {
  transition: var(--timeing);
  width: 45px;
  transform: rotate(45deg) translate(11px, 14px);
}

.bars.active div:nth-child(2) {
  transition: var(--timeing);
  width: 45px;
  transform: rotate(-45deg) translate(-12px, 8px);
}

.bars.active div:nth-child(3) {
  background-color: transparent;
  transition: var(--timeing);
  width: 0px;

  transform: scale(0);
  /* transform: rotate(-37deg) translate(12px, 14px); */

}



.step-container {
  display: flex;
  justify-content: space-between;
  transition: var(--timeing);
}

.step-container .step-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

}

.step-container Link {
  text-decoration: none !important;

}

.step-container .step-item:before {
  content: "";
  position: absolute;
  background-color: var(--primary-color-light);
  width: 100%;
  height: 3px;
  left: 0;
}

.step-container .step-item div {
  width: 35px;
  background-color: var(--bg-body);
  border: 3px solid var(--primary-color);
  height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
}

.step-container .step-item div:nth-child(1).active {
  background-color: var(--primary-color);
  color: #fff;
}

.step-container .step-item-title {
  transition: var(--timeing);
}


/* start spring loading */
:where(.css-dev-only-do-not-override-i0102m).ant-spin .ant-spin-dot-item {

  background-color: var(--primary-color);
}

:where(.css-dev-only-do-not-override-i0102m).ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: var(--primary-color);
  border: 0px solid transparent;
  color: #fff;
}


/* end spring loading */


/* End Header Home Page */

/*************** Start Main ***************/
/* here all data */
.main {
  /* margin-right: 266px; */
  margin-right: 230px;
}

.header-student-width {
  width: calc(100% - 266px);
}

/*************** End Main ***************/

/* Start Shadow and timeline*/
.style-shadow-small {
  box-shadow: 0 0 10px rgba(31, 31, 31, 0.05)
}

.style-shadow-large {
  box-shadow: 0 0 10px rgba(31, 31, 31, 0.1)
}

.style-shadow-large-extra {
  box-shadow: 0 0 10px rgba(31, 31, 31, 0.2)
}

/* style for Pagination */
.page-item {
  padding: 2px;
}

.page-link {
  border-radius: 5px !important;
  user-select: none;
  color: var(--primary-color) !important;

}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: transparent !important;
}

/* End Shadow */
.style-landing-img {
  height: 300px;
  width: 450px;
}

/*////////////////////// start Join-Reqesut ////////////////////// */
#img-join-request-width {
  width: 150px;
  height: 100px;
}

#img-join-request-details-width {
  width: 250px;
  height: 150px;
  transition: var(--timeing);
}

/* change color placeholder the input */
.form-control {
  border: 3px solid #f6f6f6 !important;
  /* transition: 0 !important;
  box-shadow: 0 0 0 transparent !important; */
}

/* ficus border primary color */
.form-control:focus {
  border: 3px solid var(--primary-color) !important;
  transition: 0 !important;
  box-shadow: 0 0 0 !important;

}

.form-control::placeholder {
  /* background-color: red; */
  color: #1e1e1e65 !important;
  font-weight: bold;
  font-size: 13px;
  /* color: var(--black-color); */
  /* color: #1e1e1e65; */
  /* color: #f00; */
}

:where(.css-dev-only-do-not-override-i0102m).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  /* background-color: #f00; */
  border: 3px solid #f6f6f6 !important;
  padding: 10px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-focused:where(.css-dev-only-do-not-override-i0102m).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 3px solid var(--primary-color) !important;

  box-shadow: 0 0 0 !important;
}

/* start inputs password eyes */
:where(.css-dev-only-do-not-override-i0102m).ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  /* font-size: 14px; */
  /* line-height: 1.5714285714285714; */
  background: #f9f9f9;
  background-image: none;
  border: 3px solid #f6f6f6 !important;
  transition: all 0.2s;
}

:where(.css-i0102m).ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  /* font-size: 14px; */
  /* line-height: 1.5714285714285714; */
  background: #f9f9f9;
  background-image: none;
  border: 3px solid #f6f6f6 !important;
  transition: all 0.2s;
}

:where(.css-dev-only-do-not-override-i0102m).ant-input-affix-wrapper>input.ant-input {
  border: 0px solid #f6f6f6 !important;
  box-shadow: 0 0 0 !important;
  background-color: #f9f9f9;
  font-size: 1rem;
}

:where(.css-dev-only-do-not-override-i0102m).ant-input-affix-wrapper>input.ant-input::placeholder {
  font-weight: bold !important;

}

:where(.css-dev-only-do-not-override-i0102m).ant-input-affix-wrapper:focus,
:where(.css-dev-only-do-not-override-i0102m).ant-input-affix-wrapper-focused,
:where(.css-i0102m).ant-input-affix-wrapper:focus,
:where(.css-i0102m).ant-input-affix-wrapper-focused,
:where(.css-i0102m).ant-input-affix-wrapper:focus {
  border-color: var(--primary-color) !important;

}

.ant-input-affix-wrapper {
  box-shadow: 0 0 0 !important;
}

:where(.css-dev-only-do-not-override-i0102m).ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 17px;
  transition: all 0.3s;


}

:where(.css-dev-only-do-not-override-i0102m).ant-input-affix-wrapper .ant-input-prefix {
  color: var(--primary-color);
  font-size: 17px !important;

}

:where(.css-dev-only-do-not-override-i0102m).ant-input-affix-wrapper .anticon.ant-input-password-icon:hover {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 17px;
  transition: all 0.3s;
}

/* search */
.ant-input-group-addon {
  display: none !important;
}

.ant-input-search {
  border-radius: 7px !important;
  padding: 0 !important;
}

:where(.css-i0102m).ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.3s;
  font-size: 17px;
}

:where(.css-i0102m).ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 4px;
  color: var(--primary-color);
}

:where(.css-i0102m).ant-input-affix-wrapper:focus,
:where(.css-i0102m).ant-input-affix-wrapper-focused {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
}

:where(.css-i0102m).ant-input-affix-wrapper .anticon.ant-input-password-icon:hover {
  color: var(--primary-color) !important;
}

.ant-input-search :where(.css-dev-only-do-not-override-i0102m).ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  /* border-radius: 7px !important;
  background-color: #fff !important;
  padding: 4px 11px; */

  border-radius: 7px !important;

}

/* 
:where(.css-i0102m).ant-input-affix-wrapper>input.ant-input {
  border: 0px solid #f6f6f6 !important;
  box-shadow: 0 0 0 !important;
  background-color: #f9f9f9;
  font-size: 1rem;
} */

:where(.css-i0102m).ant-input-affix-wrapper>input.ant-input {
  background-color: #f9f9f9 !important;
  border: 0 solid #f9f9f9 !important;
  box-shadow: 0 0 0 !important;
  font-size: 1rem;
}

/* end inputs password eyes */

:where(.css-i0102m).ant-drawer .ant-drawer-header {
  display: none !important;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1.5;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}


/*////////////////////// End Join-Reqesut ////////////////////// */
.card-join-request-details {
  width: 75%;
}

.student-status-card-width {
  width: 400px;
  height: 200px;
  animation: animateStatusCard 2s infinite linear alternate;
}

.landing-card-width {
  width: 550px;
  height: 280px;
  animation: animateLandingCard 2s infinite linear alternate;
  position: relative;
  transition: var(--timeing);

}

.landing-card-width::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  bottom: -50px;
  left: 5%;
  animation: animateLandingContentCard 2s infinite linear alternate;

  /* filter: blur(5px); */

}

@keyframes animateLandingCard {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(10px);
  }
}

@keyframes animateLandingContentCard {
  0% {
    /* transform: translateY(-10px); */
    filter: blur(20px);
    /* background-color: rgba(0, 0, 0, 0.3); */
  }

  100% {
    /* transform: translateY(10px); */
    filter: blur(8px);
    /* background-color: rgba(0, 0, 0, 0.3)/; */


  }
}

@keyframes animateStatusCard {
  0% {
    transform: translateY(-10px);
    box-shadow: 0px 44px 42px -35px rgba(30, 30, 30, 0.6);
  }

  100% {
    transform: translateY(10px);
    box-shadow: 0px 44px 42px -35px rgba(30, 30, 30, 0.4);
  }
}


.student-status-card-width .student-status-card-width-collage-major {
  width: 100px;
}

.height-landing-page {
  min-height: 100vh;
}

.scalc-text-landing {
  /* animation: animateLandingTextCard 10s infinite linear alternate; */
  font-size: 25px;

}


@keyframes animateLandingTextCard {
  0% {
    /* transform: translateY(-10px); */
    transform: scale(0.6);


  }

  100% {
    /* transform: translateY(10px); */
    transform: scale(1);

  }
}


.btn-carousel {
  /* background-color: blue !important; */
  /* padding: 5px 15px !important; */
  width: 30px !important;
  height: 10px !important;
  border-radius: 50px !important;
}

/* .carousel-indicators{
  bottom: -73px !important;
} */

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -73px !important;
  left: 0;
  z-index: 2;
  display: block;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
  transition: background-color 0.4s ease-in !important;

}

.carousel-indicators div {
  width: 12px !important;
  height: 12px !important;
  padding: 0;
  margin: 4px;
  background-color: var(--primary-color-opacity) !important;
  border-radius: 15px !important;
  border: 0 !important;
  transition: background-color 0.4s ease-in !important;


  /* opacity: 0.5; */
  /* transition: background-color 0.3s  !important; */
}

.carousel-indicators div.active {
  width: 35px !important;
  transition: background-color 0.4s ease !important;
  opacity: 1;
  background-color: var(--primary-color) !important;

}

/*  */
.size-Stepper {
  font-size: 20px;
  font-weight: bold;
}



/* my Custom timeline  */
/* label */
#RFS-Label {
  color: var(--primary-color);
  font-weight: bold;
}

/* btn */
#RFS-StepButton {
  padding: 0 10px;
  /* width: 30px; */
  /* height: 30px; */
  border-radius: 50px;
  text-align: center;
  border-width: 0;
}

.timeline {
  position: relative;
  /* margin-right: 150px; */
}

.timeline::before {
  content: "";
  position: absolute;
  background-color: var(--green-light-color);
  width: 4px;
  height: 100%;
  right: 120px;

}

.timeline div {
  position: relative;
  margin: 50px 0;
  display: flex;
}

.timeline div::before {
  content: "";
  position: absolute;
  background-color: #105651;
  width: 20px;
  height: 20px;
  transform: translateY(10%);
  top: 0;
  right: 112px;
  border-radius: 50px;

}

.timeline div span {
  margin: 0 50px;

  font-weight: bold;
  display: inline-block;
}

.timeline div span:first-child {
  /* background-color: red; */
  /* margin-right: -40px; */
  width: 200px;
}




:where(.css-dev-only-do-not-override-nnuwmp).ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0px !important;
  overflow: auto;
}

:where(.css-i0102m).ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0px !important;
  overflow: auto;
}

.timeline-label {
  position: relative;

}

.timeline-label::before {
  content: "";
  position: absolute;
  background-color: var(--green-light-color);
  width: 3px;
  height: 100%;
  right: 138px;
  top: 50%;
  transform: translateY(-50%);
  /* z-index: 0; */
  display: none;
}


.timeline-label .timeline-item {
  position: relative;
}


.timeline-label .timeline-item div:nth-child(1) {
  position: relative;
  color: var(--primary-color);
}

.timeline-label .timeline-item div:nth-child(1)::before {
  content: "";
  position: absolute;
  background-color: var(--primary-color);
  width: 15px;
  height: 15px;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50px;
  z-index: 1;
  outline: 2px solid #fff;
}

.timeline-label .timeline-item div:nth-child(1):after {
  content: "";
  position: absolute;
  background-color: var(--green-light-color);
  width: 3px;
  height: 100%;
  left: 26px;
  top: 100%;
  transform: translateY(-50%);
  border-radius: 50px;
  z-index: 0;
}

.timeline-label .timeline-item div:nth-child(2) {
  /* z-index: 1; */
  /* position: absolute; */
  position: relative;
  /* right: 131px; */
  line-height: 1.7;

}


/* //////////////// Start Duplicate Style /////////////////// */

:where(.css-dev-only-do-not-override-nnuwmp).ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0px !important;
  overflow: auto;
}

:where(.css-i0102m).ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0px !important;
  overflow: auto;
}

/* start login width */
.login-width {
  width: 500px;
}

/* //////////////start Poll /////////////////*/



.poll-container {
  position: relative;


}

.poll-container::before {
  content: "";
  position: absolute;
  background-color: var(--black-color-opacity);
  width: 4px;
  height: 100%;
  right: 22px;
  border-radius: 50px;
  top: 0;
}

.poll-container .poll-container-item {
  position: relative;
}


.poll-container .poll-container-item .poll-container-item-title {
  position: relative;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* color: var(--green-light-color); */
  font-weight: bold;
  /* font-size: 18px; */

}


.poll-container .poll-container-item .poll-container-item-title::before {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.poll-container .poll-container-item .poll-container-item-title-dark {
  color: var(--black-color);
  position: relative;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--green-light-color);
  font-weight: bold;
}

.poll-container .poll-container-item .poll-container-item-title-dark::before {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}




.poll-container .poll-container-item .poll-container-item-sub-title {
  padding-right: 25px;
  line-height: 1.9;
  font-size: 14px;
}

/* //////////////end Poll /////////////////*/

/* auto-fill inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: var(--black-color);
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

/* 
.card-img-top {
  width: 50px;
  height: 100px;
} */
:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-expand,
:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-edit,
:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-copy {
  color: var(--primary-color);
  text-decoration: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  transition: color 0.3s;
  margin-inline-start: 4px;
}

:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-expand:focus,
:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-edit:focus,
:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-copy:focus,
:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-expand:hover,
:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-edit:hover,
:where(.css-dev-only-do-not-override-i0102m).ant-typography .ant-typography-copy:hover {
  color: var(--primary-color-opacity);
}

:where(.css-dev-only-do-not-override-i0102m).ant-drawer .ant-drawer-header {
  display: none;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1.5;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

/* account profile change background active */
.dropdown-item:active {
  background-color: var(--primary-color) !important;
}

.dropdown-item:active span {
  color: #fff !important;
}


.nav-tabs {

  border-width: 0 !important;

  display: flex !important;
  flex-wrap: nowrap !important;
  overflow: auto !important;
}

.nav-tabs .nav-link {
  border-width: 0 !important;
  margin: 0 !important;
  word-wrap: nowrap;
  color: var(--primary-color);
  background-color: var(--primary-color-light) !important;
  border-radius: 0 !important;
}

.nav-tabs .nav-link:first-child {
  border-radius: 0 5px 5px 0 !important;
}

.nav-tabs .nav-link:last-child {
  border-radius: 5px 0 0 5px !important;
}

.nav-tabs .nav-link.active {
  border-width: 0 !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.nav-tabs .nav-link:hover.nav-link.active {
  color: #fff !important;

}

.nav-tabs .nav-link:hover {
  border-width: 0 !important;
  color: #1E1E1E !important;


}



/*Start check box my Custom */
input::auto-fill {
  background-color: rgb(228, 14, 14) !important;
}

.form-check-input {
  background-color: transparent !important;
  border: 5px !important;
  padding: 8px;
}

.form-check-input[type=checkbox] {
  box-shadow: none;
  border-radius: 0.25em;
  border: 3px solid var(--primary-color) !important;
  background-color: transparent !important;
}

.form-check-input[type=checkbox]:checked {
  background-color: var(--primary-color) !important;
}

.form-check-input[type=radio] {
  box-shadow: none;
  border-radius: 0.25em;
  border: 3px solid var(--primary-color) !important;
  background-color: transparent !important;
}

.form-check-input[type=radio]:checked {
  background-color: var(--primary-color) !important;
}


/*End check box my Custom */

/* Start change color stars  */
.react-stars span svg path {
  color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

/* End change color stars  */

/* start popup done my custom */

/* this is remove {none} */
.modal::-webkit-scrollbar {
  display: block !important;
}

.modal-content {
  pointer-events: all !important;
  border-width: 0 !important;

}



.popup-done-my-custom-container .popup-done-my-custom-content {
  z-index: 2;
  background-color: #fff;

  border-radius: 13px;
}

.popup-done-my-custom-container .popup-done-my-custom-img {
  background-color: var(--primary-color);
  z-index: 3;
  border-radius: 13px 13px 0 0;

}

/* style  Multiselect */
.highlightOption {
  background: var(--primary-color) !important;
  color: #fff;
}

.optionContainer::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

.optionContainer::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 30px;
}

.optionContainer .option {
  font-weight: bold;
  background-color: transparent !important;
  color: var(--black-color) !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  margin: 5px !important;

}

.multiSelectContainer ul {
  border: 3px solid var(--primary-color) !important;
  border-radius: 4px !important;
  display: block !important;
  margin: 3px 0 !important;
  max-height: 250px !important;
  overflow-y: auto !important;
  padding: 0 !important;
}


.search-wrapper input::placeholder {
  color: var(--black-color);
  opacity: 0.5 !important;
  font-weight: bold;
  text-align: center;
}

.multiSelectContainer li:hover {
  background: var(--primary-color) !important;
  color: #fff !important;
  cursor: pointer;
}

.highlightOption:hover {
  background: var(--primary-color) !important;
  color: #fff;
}

.chip {
  align-items: center;
  background: var(--primary-color) !important;
  border-radius: 11px;
  color: #fff !important;
  display: inline-flex;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
}

.chip img {
  margin: 0 5px !important;
}

/* style Upload file */
.style-title-head {
  font-size: 18px;
  transition: var(--timeing);
}


.DYXVr {
  background-color: #126c6632 !important;


  /* position: relative; */
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;

  align-items: center;
  justify-content: space-between;
  min-width: 100% !important;
  max-width: 100% !important;
  /* width: 100% !important; */
  height: 250px !important;
  border: 6px dashed var(--primary-color) !important;
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.DYXVr svg {
  /* background-color: red !important; */
  width: 300px;
  display: none;
}


.cgTcOH {
  color: var(--primary-color) !important;
  font-weight: bold;
  margin: 0 10px;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  font-size: 18px !important;

}

.style-btn-upload-width {
  width: 160px;
}

.file-types {
  color: var(--primary-color) !important;
  font-weight: bold;
  margin-top: 50px;

}

.cgTcOH span {
  color: var(--primary-color) !important;
  text-decoration: none !important;
  font-weight: bold;
  font-size: 18px;

}

/* style table thead font size */
.style-thead-tbody-fs {
  font-size: 16px;
  transition: var(--timeing);

}

.style-Breadcrumbs-fs {
  font-size: 16px !important;
  transition: var(--timeing);
}

/* start style Notificaitions Toast */
.notification-container .title,
.notification-container .message {
  color: #fff !important;
  margin: 0 !important;
}



/* End style Notificaitions Toast */

/************** Start custom-input-file **************/

.custom-input-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer !important;
  z-index: 100;
  opacity: 0;
  width: 100%;
}

/************** End custom-input-file **************/


/* .popup-done-my-custom-container .popup-done-my-custom-text {}

.popup-done-my-custom-container .popup-done-my-custom-text .popup-done-my-custom-text-title {}

.popup-done-my-custom-container .popup-done-my-custom-text .popup-done-my-custom-text-sub-title {}

.popup-done-my-custom-container .popup-done-my-custom-btn {} */


/* end popup done my custom */

/* //////////////// End Duplicate Style /////////////////// */



/* media Query */


/* large */
@media (min-width:1200px) {
  .timeline {
    margin-right: 150px;
  }
}

@media (max-width:1200px) {
  .landing-card-width {
    width: 450px;
    transition: var(--timeing);

  }
}

@media (min-width:992px) {
  .modal-dialog-centered-width {
    width: 25% !important;
  }


}




@media (max-width:992px) {
  .card-join-request-details {
    width: 100%;
  }

  .modal-dialog-centered-width {
    width: 30% !important;
  }

  .timeline-label .timeline-item div:nth-child(1)::before {
    right: 0;
    /* top: 50%; */
  }

  .timeline-label .timeline-item div:nth-child(1):after {
    right: 6px;
    display: none;
  }

  .border-radius-contact-us-1 {
    border-radius: 0 0 var(--radius) var(--radius);
  }

  .border-radius-contact-us-2 {
    border-radius: var(--radius) var(--radius) 0 0;
  }

  .style-thead-tbody-fs {
    font-size: 14px;
    transition: var(--timeing);

  }

  .landing-page-img {
    max-width: 100%;
    max-height: 100%;
  }

  .wavy-width-landing {
    width: 50px;
  }

  .landing-page-img-wavy {
    max-width: 100%;
    max-height: 100%;
  }


  .style-translateY-advantages:nth-child(even) {
    transform: translateY(0px);
  }

  .style-Breadcrumbs-fs {
    font-size: 14px !important;
    transition: var(--timeing);
  }

  .style-title-head {
    font-size: 16px;
    transition: var(--timeing);
  }

  .poll-container .poll-container-item .poll-container-item-sub-title {

    font-size: 12px;
  }




  .timeline-label::before {
    right: 6px;
    display: block;

  }
}



/* medium */


@media (min-width:768px) {
  .title-company-name-fs {
    font-size: 18px;
    transition: var(--timeing);
  }

}

@media (max-width:768px) {
  .main {
    margin-right: 0;
  }

  /* .main {
    margin-right: 96px;
  } */
  .title-company-name-fs {
    font-size: 16px;
    transition: var(--timeing);
  }

  .header-student-width {
    width: calc(100% - 96px);
  }

  .height-landing-page {
    min-height: 75vh;
  }

  .style-img-goal {
    width: 200px;
    height: 200px;
    transition: var(--timeing);
  }

  .title-head-special div {
    font-size: 25px;

  }

  .text-title-icons-blobs {
    font-size: 14px;
    transition: var(--timeing);
  }

  .style-icons-homepage {
    width: 45px;
    height: 45px;
    transition: var(--timeing);
  }

  .style-blob-homepage {
    width: 95px;
    height: 95px;
    transition: var(--timeing);
  }

  .modal-dialog-centered-width {
    width: 40% !important;
  }

  .style-margin-about-text {
    transition: var(--timeing);
    margin-top: 0px;
    text-align: center;
  }

  .text-sub-title {
    font-size: 16px;

  }

  .sideBar-position {
    width: 96px;
    transition: 0.4s;

  }

  .date-sidebar {
    font-size: 13px;
  }

  .step-container .step-item div {
    /* background-color: #9bae97; */
    width: 30px;
    height: 30px;

  }

  .step-container .step-item-title {
    font-size: 13px;
  }

  .style-landing-img {

    max-width: 100%;
  }
}

/* small */



@media (max-width:576px) {

  /* .main {
    margin-right: 0;

  } */
  .login-width {
    width: 100%;
  }

  .wavy-width-landing {
    display: none;
  }

  .image-gallery-right-nav,
  .image-gallery-left-nav {
    height: 30px;
    width: 30px;
  }



  .image-gallery-right-nav {
    right: -15px;

  }

  .image-gallery-left-nav {
    left: -15px;


  }


  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 23px;
    width: 40px;


  }

  .header-student-width {
    width: 100%;
  }

  .sideBar-position {
    width: 0px;
    transition: 0.4s;

  }

  .form-check-input {
    padding: 7px;
  }

  .size-Stepper {
    font-size: 12px;
  }

  .style-thead-tbody-fs {
    font-size: 12px;
    transition: var(--timeing);

  }

  .step-container .step-item-title {
    font-size: 10px;
  }


  #img-join-request-width {
    max-width: 100%;
  }

  .modal-dialog-centered-width {
    width: 60% !important;
  }

  .student-status-card-width {
    width: 80%;
  }

  .landing-card-width {
    width: 100%;

  }

  /* .style-btn-upload-width {
    width: 100px;
  } */


}


@media (max-width:450px) {
  .style-drop-drog label {
    font-size: 16px;
  }

  #img-join-request-details-width {
    width: 170px;
    height: 100px;
  }

  .hhhh {
    font-size: 40px;
    transition: var(--timeing);
  }

  .scalc-text-landing {
    font-size: 15px;

  }

  .modal-dialog-centered-width {
    width: 90% !important;
    /* padding: 0 10px; */
  }


  .student-status-card-width {
    width: 100%;
  }

  .style-landing-img {
    height: 150px;

  }
}

@media (max-width:350px) {


  #img-join-request-details-width {
    max-width: 100%;
  }

  .notification-container {
    width: 100% !important;
  }

}