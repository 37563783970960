// change the theme

  $primary: #f00;

//   $theme-colors: (
//     "primary": $primary,

//   );
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
